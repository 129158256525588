import React from 'react';
import PropTypes from 'prop-types';
import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react';
import { themeJSON } from './theme_json';

const options = {
  isAutoSave: false,
  showState: false,
  showThemeTab: false,
  showJSONEditorTab: false,
  showLogicTab: false,
  showToolbox: false,
};

const SurveyPreview = ({ survey }) => {
  const creator = new SurveyCreator(options);
  creator.theme = themeJSON;

  const content = survey.content;

  // TODO: none of these settings should be needed here, they should all come from the designer's JSON schema.
  content['focusFirstQuestionAutomatic'] = false;
  content['questionTitlePattern'] = 'numRequireTitle';
  creator.JSON = content;
  creator.showPreview();

  return (
    <div className="survey survey--preview" style={{ height: '100vh' }}>
      <SurveyCreatorComponent creator={creator} />
    </div>
  );
};

SurveyPreview.propTypes = {
  survey: PropTypes.object.isRequired,
};

export default SurveyPreview;
