import React, { useEffect } from 'react';
import { ConfirmDialogProvider, useConfirmDialog } from '../modals/confirm_dialog';

const useToggleSurveyStatus = (updateFunc, surveyId, callbackFunc = {}, extraInfo = {}) => {
  const { showConfirmDialog, hideConfirmDialog } = useConfirmDialog();

  const confirmClosure = () => {
    return new Promise((resolve, reject) => {
      const participantWord = extraInfo.inCompleteCount === 1 ? 'participant' : 'participants';

      showConfirmDialog({
        title: 'Are you sure you want to close this Survey?',
        description: `Closing this survey will result in ${extraInfo.inCompleteCount} ${participantWord} being unable to submit their survey. Are you sure you want to proceed?`,
        actions: [
          {
            label: 'Cancel',
            onClick: () => {
              hideConfirmDialog();
              reject();
            },
            variant: 'contained',
            color: 'secondary',
          },
          {
            label: 'Yes, Close Survey',
            onClick: () => {
              hideConfirmDialog();
              resolve();
            },
            variant: 'contained',
            color: 'primary',
            autoFocus: false,
          },
        ],
        maxWidth: 'sm',
      });
    });
  };

  useEffect(() => {
    const switchElement = document.getElementById('survey_status');

    const handleSwitchChange = async (e) => {
      // Immediately revert the switch
      e.target.checked = !e.target.checked;

      const intendedState = !e.target.checked; // This is what the user tried to set
      const status = intendedState ? 'open' : 'closed';

      if (status === 'closed' && extraInfo.inCompleteCount > 0) {
        try {
          await confirmClosure();
          // If confirmed, set the switch to the intended state
          e.target.checked = intendedState;
        } catch (error) {
          // If rejected, the switch is already at the original state
          return;
        }
      } else {
        // If no confirmation is needed, set the switch to the intended state
        e.target.checked = intendedState;
      }

      await updateFunc(surveyId, { status: status });

      if (callbackFunc && typeof callbackFunc === 'function') {
        callbackFunc(status);
      }
    };

    if (switchElement) {
      switchElement.addEventListener('change', handleSwitchChange);
    }

    // Cleanup: remove event listener when component unmounts
    return () => {
      if (switchElement) {
        switchElement.removeEventListener('change', handleSwitchChange);
      }
    };
  }, []);
};

export default useToggleSurveyStatus;
