import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, TableSortLabel, Button, Tooltip, Snackbar } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faXmark } from '@fortawesome/pro-solid-svg-icons';
import capitalize from 'lodash/capitalize';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Zoom from '@mui/material/Zoom';
import { formatDate } from '../../helpers/date_utils';

const sortParticipants = (participants, property, order) => {
  if (!participants) return [];
  const participantsCopy = [...participants];
  let compareFunction;

  switch (property) {
    case 'first_name':
      compareFunction = (a, b) => a[property]?.localeCompare(b[property]);
      break;
    case 'last_name':
      compareFunction = (a, b) => a[property]?.localeCompare(b[property]);
      break;
    case 'email':
      compareFunction = (a, b) => a[property]?.localeCompare(b[property]);
      break;
    default:
      compareFunction = () => 0;
  }

  return participantsCopy.sort((a, b) => {
    let comparison = compareFunction(a, b);
    return order === 'asc' ? comparison : -comparison;
  });
};

const SortableTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: 'email',
      numeric: false,
      label: 'Email',
      sortable: true,
      sx: { minWidth: '40%' },
    },
    {
      id: 'status',
      numeric: false,
      label: 'Status',
      sortable: true,
      sx: { width: '50px' },
    },
    {
      id: 'progress',
      numeric: false,
      label: 'Progress',
      sortable: true,
      sx: { width: '50px' },
    },
    {
      id: 'completed',
      numeric: false,
      label: 'Completed at',
      sortable: true,
      sx: { width: '145px' },
    },
    {
      id: 'actions',
      numeric: false,
      label: 'Actions',
      sortable: false,
      sx: { width: '100px' },
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            {...(headCell.sx && { sx: headCell.sx })}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const DataRow = ({ participant, onEdit, onDelete, onSendInvitation, onSendReminder, isSurveyOpen }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [toastOpen, setToastOpen] = useState(false);
  const open = Boolean(anchorEl);
  const menuButtonStyle = {
    height: '36.5px',
    minWidth: 'auto',
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const isPendingInvite = () => {
    return participant?.status === 'pending';
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(participant.take_survey_link);
    setToastOpen(true);
  };

  return (
    <TableRow>
      <TableCell align="left">{participant.email}</TableCell>
      <TableCell align="left">{capitalize(participant.status)}</TableCell>
      <TableCell align="left">{participant.progress ? `${participant.progress}%` : ''}</TableCell>
      <TableCell align="left">{participant.completed_at ? formatDate(participant.completed_at) : ''}</TableCell>
      <TableCell>
        <Stack direction="row" spacing={1} alignItems="center">
          {typeof onEdit === 'function' && (
            <Button variant="contained" color="secondary" data-cy="button-edit-participant" onClick={() => onEdit(participant.id)}>
              Edit
            </Button>
          )}
          <Button
            id="menu-button"
            aria-controls={open ? 'positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleMenuClick}
            variant="contained"
            color="secondary"
            className="ms-2"
            style={menuButtonStyle}
          >
            <FontAwesomeIcon icon={faEllipsisH} />
          </Button>
          <Menu
            id="positioned-menu"
            aria-labelledby="menu-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Tooltip TransitionComponent={Zoom} title={isSurveyOpen ? '' : 'SURVEY CLOSED!'} placement="left">
              <span style={{ margin: 0, padding: 0 }}>
                <MenuItem onClick={() => onSendInvitation(participant.id)} disabled={!isSurveyOpen}>
                  Send Invitation
                </MenuItem>
              </span>
            </Tooltip>
            <Tooltip TransitionComponent={Zoom} title={isSurveyOpen ? '' : 'SURVEY CLOSED!'} placement="left">
              <span>
                <MenuItem
                  disabled={isPendingInvite() || !isSurveyOpen || participant.completed_at !== null}
                  onClick={() => onSendReminder(participant.id)}
                >
                  Send Reminder
                </MenuItem>
              </span>
            </Tooltip>
            <MenuItem onClick={handleCopyLink} disabled={!isSurveyOpen}>
              Copy Survey Link
            </MenuItem>
            <MenuItem onClick={() => onDelete(participant.id)}>Remove</MenuItem>
            <Snackbar
              open={toastOpen}
              message="Survey link copied to clipboard"
              autoHideDuration={5000}
              onClose={() => setToastOpen(false)}
              action={<FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faXmark} onClick={() => setToastOpen(false)} />}
            />
          </Menu>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

const DataTable = ({ participants, onEdit, onDelete, onSendInvitation, onSendReminder, emptyMessage, isSurveyOpen }) => {
  const [orderBy, setOrderBy] = useState('created_at');
  const [order, setOrder] = useState('desc');
  const [sortedParticipants, setSortedParticipants] = useState(participants || []);

  useEffect(() => {
    setSortedParticipants(sortParticipants(participants, orderBy, order));
  }, [participants, orderBy, order]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);

    setSortedParticipants(sortParticipants(participants, property, newOrder));
  };

  if (participants.length > 0) {
    return (
      <TableContainer>
        <Table size={'small'} sx={{ marginTop: 4 }}>
          <SortableTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
          <TableBody>
            {sortedParticipants.map((participant, index) => (
              <DataRow
                key={`${participant.id}_${index}`}
                participant={participant}
                onEdit={onEdit}
                onDelete={onDelete}
                onSendInvitation={onSendInvitation}
                onSendReminder={onSendReminder}
                isSurveyOpen={isSurveyOpen}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return <div className="text-center">{emptyMessage}</div>;
  }
};

DataTable.propTypes = {
  participants: PropTypes.array,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onSendInvitation: PropTypes.func,
  onSendReminder: PropTypes.func,
  emptyMessage: PropTypes.string,
  isSurveyOpen: PropTypes.bool,
};

export default DataTable;
