import React, { useState, useRef, useEffect, useMemo } from 'react';
import Layout from '../layouts/application';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../constants/theme';
import { createApi as createSurveyApi } from '../../apis/surveys/api';
import { createApi as createSurveyParticipantsApi } from '../../apis/survey_participants/api';
import { getJobLog } from '../../apis/job_logs/api';
import ParticipantsTable from '../../components/survey_participants/table';
import { ConfirmDialogProvider, useConfirmDialog } from '../../components/modals/confirm_dialog';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import EmailDialog from '../../components/survey_participants/email_dialog';
import { Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate, faEllipsisH } from '@fortawesome/pro-solid-svg-icons';
import ParticipantDialog from '../../components/survey_participants/participant_dialog';
import useToggleSurveyStatus from '../../components/surveys/use_toggle_survey_status';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

const Container = (props) => {
  const isInitialMount = useRef(true);
  const [participants, setParticipants] = useState(props.participants);
  const [uploadedParticipantsCount, setUploadedParticipantsCount] = useState(0);
  const [isSendInvitationsDialogOpen, setIsSendInvitationsDialogOpen] = useState(false);
  const [isSendRemindersDialogOpen, setIsSendRemindersDialogOpen] = useState(false);
  const [isEmailDispatchInProgress, setIsEmailDispatchInProgress] = useState(props.survey.is_email_dispatch_in_progress);

  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [isParticipantDialogOpen, setIsParticipantDialogOpen] = useState(false);
  const [editParticipant, setEditParticipant] = useState(null);
  const [isSurveyOpen, setIsSurveyOpen] = useState(props.survey.status === 'open');

  const pendingCount = useMemo(() => {
    return participants.filter((p) => p.status === 'pending').length;
  }, [participants]);

  const inCompleteCount = useMemo(() => {
    return participants.filter((p) => p.status !== 'pending' && p.completed_at === null).length;
  }, [participants]);

  const totalParticipants = useMemo(() => participants.length, [participants]);

  useEffect(() => {
    let pollingInstance;
    (async () => {
      try {
        await fetchEmailDispatchStatus();
        if (isEmailDispatchInProgress) {
          pollingInstance = startEmailDispatchStatusPolling();
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    })();

    return () => {
      if (pollingInstance) {
        clearInterval(pollingInstance);
      }
    };
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (!isEmailDispatchInProgress) {
        fetchParticipants();
      }
    }
  }, [isEmailDispatchInProgress]);

  const fileInput = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const surveyApi = createSurveyApi(props.base_url);
  const surveyParticipantsApi = createSurveyParticipantsApi(`${props.base_url}/${props.survey.id}/participants`);

  useToggleSurveyStatus(
    surveyApi.update,
    props.survey.id,
    (status) => {
      setIsSurveyOpen(status === 'open');
    },
    { inCompleteCount }
  );

  const { showConfirmDialog, hideConfirmDialog } = useConfirmDialog();

  const handleClickOpen = (e) => {
    fileInput.current.click();
    setUploadedParticipantsCount(0);
  };

  const handleAddParticipant = () => {
    setIsParticipantDialogOpen(true);
    setEditParticipant(null);
  };

  const uploadCSV = async (e) => {
    showConfirmDialog({
      title: 'Processing?',
      description: 'Uploading and processing participants...',
      maxWidth: 'xs',
    });
    const response = await surveyApi.uploadParticipantsCsv(props.survey.id, e.target.files[0]);
    const { id } = response;

    let log;
    const i = setInterval(async () => {
      log = await getJobLog(id);
      if (log.status !== 'pending') {
        clearInterval(i);
        if (log.status === 'failure') {
          showConfirmDialog({
            title: 'Unable to process!',
            description: `There was an error in your CSV. Please ensure all email addresses are valid and try again. Error: ${log.error_messages}`,
            maxWidth: 'xs',
            actions: [
              {
                label: 'OK',
                onClick: hideConfirmDialog,
                variant: 'contained',
                color: 'primary',
                autoFocus: false,
              },
            ],
          });
          fileInput.current.value = '';
        } else if (log.status === 'success') {
          const participants = await surveyApi.fetchParticipants(props.survey.id);
          setParticipants(participants);
          showConfirmDialog({
            type: 'success',
            title: 'Success!',
            description: `Successfully uploaded ${log.additional_data.csv_rows} participant emails. You can send the invites now or later. Your uploaded list will remain saved.`,
            maxWidth: 'xs',
            actions: [
              {
                label: 'OK',
                onClick: hideConfirmDialog,
                variant: 'contained',
                color: 'primary',
                autoFocus: true,
              },
            ],
          });
          fileInput.current.value = '';
        }
      }
    }, 3000);
  };

  const handleDelete = async (id) => {
    await surveyParticipantsApi.delete(id);
    setParticipants(participants.filter((participant) => participant.id !== id));
    hideConfirmDialog();
  };

  const confirmDelete = (id) => {
    showConfirmDialog({
      title: 'Delete Confirmation',
      description: 'Are you sure you want to delete this participant?',
      actions: [
        {
          label: 'Cancel',
          onClick: () => hideConfirmDialog(),
          variant: 'text',
          color: 'primary',
        },
        {
          label: 'Delete',
          onClick: () => handleDelete(id),
          variant: 'contained',
          color: 'primary',
          autoFocus: false,
        },
      ],
      maxWidth: 'xs',
    });
  };

  const confirmBulkRemoveParticipants = () => {
    showConfirmDialog({
      title: 'Remove All Participants?',
      description: 'Are you sure you want to remove all participants? This action will permanently delete their responses and cannot be undone.',
      actions: [
        {
          label: 'Cancel',
          onClick: () => hideConfirmDialog(),
          variant: 'text',
          color: 'primary',
        },
        {
          label: 'Delete',
          onClick: handleBulkRemoveParticipants,
          variant: 'contained',
          color: 'primary',
          autoFocus: false,
        },
      ],
      maxWidth: 'xs',
    });
  };

  const handleBulkRemoveParticipants = async () => {
    showConfirmDialog({
      title: 'Removing Participants...',
      description: `Removing ${participants.length} and their responses...`,
      actions: [],
      maxWidth: 'xs',
    });
    await surveyApi.bulkDeleteParticipants(props.survey.id);
    const oldCount = participants.length;
    setParticipants([]);
    showConfirmDialog({
      type: 'success',
      title: 'Done!',
      description: `${oldCount} participants are removed from the survey.`,
      actions: [
        {
          label: 'OK',
          onClick: () => hideConfirmDialog(),
          variant: 'contained',
          color: 'primary',
        },
      ],
      maxWidth: 'xs',
    });
    handleMenuClose();
  };

  const handleEditParticipant = (participantId) => {
    setIsParticipantDialogOpen(true);
    setEditParticipant(participants.find((p) => p.id === participantId));
  };

  const handleCloseParticipantDialog = () => {
    setIsParticipantDialogOpen(false);
    setEditParticipant(null);
  };

  const handleSaveParticipant = async (data) => {
    if (data.id) {
      await surveyParticipantsApi.update(data.id, data);
      setParticipants(
        participants.map((p) => {
          if (p.id === data.id) {
            return data;
          }
          return p;
        })
      );
    } else {
      const response = await surveyParticipantsApi.create(data);
      setParticipants([...participants, response]);
    }
    handleCloseParticipantDialog();
  };

  const handleOpenSendInvitationsDialog = (participantIds = []) => {
    setIsSendInvitationsDialogOpen(true);

    if (participantIds.length > 0) {
      setSelectedParticipants(participantIds);
    } else {
      const filteredParticipants = participants
        .filter((p) => {
          return p.status === 'pending' && !p.completed_at;
        })
        .map((p) => p.id);

      setSelectedParticipants(filteredParticipants);
    }
  };

  const handleOpenSendRemindersDialog = (participantIds = []) => {
    setIsSendRemindersDialogOpen(true);

    if (participantIds.length > 0) {
      setSelectedParticipants(participantIds);
    } else {
      const filteredParticipants = participants
        .filter((p) => {
          return p.status === 'invited' && !p.completed_at;
        })
        .map((p) => p.id);

      setSelectedParticipants(filteredParticipants);
    }
  };

  const handleCloseSendInvitationsDialog = () => {
    setIsSendInvitationsDialogOpen(false);
    setSelectedParticipants([]);
  };

  const handleCloseSendRemindersDialog = () => {
    setIsSendRemindersDialogOpen(false);
    setSelectedParticipants([]);
  };

  const handleSendInvitations = async (config) => {
    setIsEmailDispatchInProgress(true);
    try {
      await surveyApi.sendEmails(props.survey.id, config, selectedParticipants);
      handleCloseSendInvitationsDialog();
      startEmailDispatchStatusPolling();
    } catch (error) {
      setIsEmailDispatchInProgress(false);
      console.error('An error occurred:', error);
    }
  };

  const handleSendReminders = async (config) => {
    setIsEmailDispatchInProgress(true);
    try {
      await surveyApi.sendEmails(props.survey.id, config, selectedParticipants);
      handleCloseSendRemindersDialog();
      startEmailDispatchStatusPolling();
    } catch (error) {
      setIsEmailDispatchInProgress(false);
      console.error('An error occurred:', error);
    }
  };

  const startEmailDispatchStatusPolling = () => {
    const polling = setInterval(async () => {
      const response = await fetchEmailDispatchStatus();
      if (!response) {
        clearInterval(polling);
        handleCloseSendInvitationsDialog();
      }
    }, 1000);
    return polling;
  };

  const fetchEmailDispatchStatus = async () => {
    try {
      const response = await surveyApi.fetchEmailDispatchStatus(props.survey.id);
      setIsEmailDispatchInProgress(response.is_email_dispatch_in_progress);
      return response.is_email_dispatch_in_progress;
    } catch (error) {
      console.error('An error occurred:', error);
      return false;
    }
  };

  const fetchParticipants = async () => {
    try {
      const response = await surveyApi.fetchParticipants(props.survey.id);
      setParticipants(response);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const mailMergeVars = ['first_name', 'last_name', 'email', 'role', 'school', 'department', 'subject_taught', 'tenure_with_organization'];

  const menuButtonStyle = {
    height: '36.5px',
    minWidth: 'auto',
  };

  /**
   * Patch work: length === 0 is for the default "Send Reminders" action
   * @param category
   * @returns {string|string}
   */
  const emailDialogTitle = (category) => {
    if (category === 'survey_invitation') {
      return selectedParticipants.length === 0 || selectedParticipants.length > 1 ? 'Send Invitations' : 'Send Invitation';
    }
    if (category === 'survey_reminder') {
      return selectedParticipants.length === 0 || selectedParticipants.length > 1 ? 'Send Reminders' : 'Send Reminder';
    }
    return '';
  };

  /**
   * Patch work: length === 0 is for the default "Send Reminders" action
   * @param category
   * @returns {string|string}
   */
  const emailDialogDescription = (category) => {
    if (category === 'survey_invitation') {
      return selectedParticipants.length > 1
        ? `Are you sure you want to send invitations to the ${selectedParticipants.length} pending participants?`
        : `Are you sure you want to send an invitation?`;
    }
    if (category === 'survey_reminder') {
      return selectedParticipants.length > 1
        ? `Are you sure you want to send reminders to the ${selectedParticipants.length} incomplete participants?`
        : `Are you sure you want to send a reminder?`;
    }
  };

  const exportParticipantData = () => {
    window.location.href = `${props.base_url}/${props.survey.id}/export_participants_to_xlsx.xlsx`;
  };

  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" className="mt-5">
        <div>
          <input type="file" className="d-none" ref={fileInput} onChange={uploadCSV} accept=".csv" />
          <Button variant="contained" onClick={handleClickOpen} data-cy="button-import-csv">
            Import CSV
          </Button>
          <Button variant="contained" color="secondary" className="ms-2" onClick={handleAddParticipant}>
            Add Participant
          </Button>
        </div>
        <div className="email-status">
          {isEmailDispatchInProgress ? (
            <>
              <FontAwesomeIcon icon={faRotate} className="fa-spin" style={{ fontSize: '16px', marginRight: '8px', color: '#346189' }} />
              <span style={{ color: '#346189' }}>Sending emails...</span>
            </>
          ) : (
            ''
          )}
        </div>
      </Stack>
      <Paper sx={{ p: 3, mt: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <h5>
            Participants: {totalParticipants} / Pending Invites: {pendingCount}
          </h5>
          <div>
            {pendingCount > 0 ? (
              <Tooltip TransitionComponent={Zoom} title={isSurveyOpen ? '' : 'SURVEY CLOSED!'} placement="left">
                <span style={{ margin: 0, padding: 0 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenSendInvitationsDialog()}
                    disabled={isEmailDispatchInProgress || !isSurveyOpen}
                  >
                    Send Invitations
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Tooltip TransitionComponent={Zoom} title={isSurveyOpen ? '' : 'SURVEY CLOSED!'} placement="left">
                <span style={{ margin: 0, padding: 0 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenSendRemindersDialog()}
                    disabled={isEmailDispatchInProgress || !isSurveyOpen || inCompleteCount === 0}
                  >
                    Send Reminders
                  </Button>
                </span>
              </Tooltip>
            )}
            <Button
              id="menu-button"
              aria-controls={open ? 'positioned-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleMenuClick}
              variant="contained"
              color="secondary"
              className="ms-2"
              style={menuButtonStyle}
            >
              <FontAwesomeIcon icon={faEllipsisH} />
            </Button>
            <Menu
              id="positioned-menu"
              aria-labelledby="menu-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Tooltip TransitionComponent={Zoom} title={isSurveyOpen ? '' : 'SURVEY CLOSED!'} placement="left">
                <span style={{ margin: 0, padding: 0 }}>
                  <MenuItem
                    onClick={() => handleOpenSendInvitationsDialog()}
                    disabled={isEmailDispatchInProgress || !isSurveyOpen || pendingCount === 0}
                  >
                    Send Invitations
                  </MenuItem>
                </span>
              </Tooltip>
              <Tooltip TransitionComponent={Zoom} title={isSurveyOpen ? '' : 'SURVEY CLOSED!'} placement="left">
                <span style={{ margin: 0, padding: 0 }}>
                  <MenuItem
                    onClick={() => handleOpenSendRemindersDialog()}
                    disabled={isEmailDispatchInProgress || !isSurveyOpen || inCompleteCount === 0}
                  >
                    Send Reminders
                  </MenuItem>
                </span>
              </Tooltip>
              <MenuItem onClick={exportParticipantData}>Export data</MenuItem>
              <MenuItem onClick={confirmBulkRemoveParticipants}>Remove All</MenuItem>
            </Menu>
          </div>
        </Box>
        <EmailDialog
          title={emailDialogTitle('survey_invitation')}
          description={emailDialogDescription('survey_invitation')}
          category={'survey_invitation'}
          mailMergeVars={mailMergeVars}
          isOpen={isSendInvitationsDialogOpen}
          onClose={() => handleCloseSendInvitationsDialog()}
          onSend={handleSendInvitations}
        />
        <EmailDialog
          title={emailDialogTitle('survey_reminder')}
          description={emailDialogDescription('survey_reminder')}
          category={'survey_reminder'}
          mailMergeVars={mailMergeVars}
          isOpen={isSendRemindersDialogOpen}
          onClose={() => handleCloseSendRemindersDialog()}
          onSend={handleSendReminders}
        />
        <ParticipantsTable
          participants={participants}
          onDelete={confirmDelete}
          onEdit={(participantId) => handleEditParticipant(participantId)}
          onSendInvitation={(participantId) => handleOpenSendInvitationsDialog([participantId])}
          onSendReminder={(participantId) => handleOpenSendRemindersDialog([participantId])}
          emptyMessage="No participants to display"
          isSurveyOpen={isSurveyOpen}
        />
        <ParticipantDialog
          isOpen={isParticipantDialogOpen}
          onClose={handleCloseParticipantDialog}
          onSave={(data) => handleSaveParticipant(data)}
          participant={editParticipant}
          gender_options={props.gender_options}
          race_ethnicity_options={props.race_ethnicity_options}
          role_options={props.role_options}
        />
      </Paper>
    </>
  );
};
export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <ConfirmDialogProvider>
          <Container {...props} />
        </ConfirmDialogProvider>
      </ThemeProvider>
    </Layout>
  );
};
