import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Layout from '../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../constants/theme';
import { ConfirmDialogProvider } from '../../components/modals/confirm_dialog';
import { createApi } from '../../apis/surveys/api';
import useToggleSurveyStatus from '../../components/surveys/use_toggle_survey_status';
import SurveyDesigner from '../../components/surveys/survey_designer';
import SurveyPreview from '../../components/surveys/survey_preview';
import { setLicenseKey } from 'survey-core';

const Container = (props) => {
  const api = createApi(props.base_url);
  const [survey, setSurvey] = useState(props.survey);
  const activeTab = props.active_tab || 'designer';

  setLicenseKey(props.surveyjs_key);

  useToggleSurveyStatus(api.update, props.survey.id, {}, { inCompleteCount: props.incomplete_participants });

  const handleSave = async (content) => {
    const { title, description } = content;

    const payload = {
      name: title || null,
      description: description || null,
      content: content || {},
    };

    const response = await api.update(props.survey.id, payload);
    if (!response) {
      throw new Error('Failed to save the survey');
    }

    setSurvey(response);
    return response;
  };

  return (
    <>
      {activeTab === 'designer' && <SurveyDesigner survey={survey} onSave={handleSave} hasResponses={props.survey_has_responses} />}
      {activeTab === 'preview' && <SurveyPreview survey={survey} />}
    </>
  );
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <ConfirmDialogProvider>
          <Container {...props}></Container>
        </ConfirmDialogProvider>
      </ThemeProvider>
    </Layout>
  );
};

Container.propTypes = {
  base_url: PropTypes.string.isRequired,
  survey: PropTypes.object.isRequired,
  active_tab: PropTypes.string,
};
