import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react';
import { settings } from 'survey-creator-core';
import Alert from '@mui/material/Alert';

settings.toolbox.defaultJSON.matrixdropdown = {
  columns: [
    {
      name: 'column1',
      cellType: 'radiogroup',
      isRequired: true,
      showInMultipleColumns: true,
      choices: ['item1', 'item2', 'item3', 'item4', 'item5'],
    },
  ],
  rows: ['item1', 'item2', 'item3'],
};

const questionTypes = [
  'question',
  'text',
  'checkbox',
  'radiogroup',
  'dropdown',
  'boolean',
  'matrix',
  'matrixdropdown',
  'rating',
  'ranking',
  'comment',
  'panel',
  'html',
];

const options = {
  isAutoSave: true,
  showState: true,
  showThemeTab: false,
  showJSONEditorTab: false,
  showPreviewTab: false,
  showLogicTab: false,
  showToolbox: false,
  questionTypes: questionTypes,
};

const SurveyDesigner = ({ survey, onSave, hasResponses }) => {
  const [surveyContent] = useState(() => {
    let initialContent = survey.content;
    return {
      ...initialContent,
      title: survey.name,
      description: survey.description,
      questionTitlePattern: 'numRequireTitle',
      widthMode: 'responsive',
    };
  });

  const creator = useMemo(() => new SurveyCreator(options), []);

  creator.saveSurveyFunc = async (no, callback) => {
    try {
      await onSave(creator.JSON);
      callback(no, true);
      updateTitle(creator.JSON.title);
    } catch (error) {
      console.error(error);
      callback(no, false);
    }
  };

  useEffect(() => {
    creator.showSidebar = false;
  }, [creator]);

  useEffect(() => {
    creator.JSON = surveyContent;
  }, [surveyContent]);

  const updateTitle = (title) => {
    const headerElement = document.querySelector('.project-nav h1');
    if (headerElement) {
      headerElement.innerText = title;
    }
  };

  // Properties to hide from the property grid. When all properties inside a category are hidden,
  // the category is hidden as well.
  const propertyStopList = [
    'widthMode',
    'locale',
    'mode',
    'cookieName',
    'logo',
    'logoWidth',
    'logoHeight',
    'logoFit',
    'firstPageIsStarted',
    'showPrevButton',
    'goNextPageAutomatic',
    'showCompletedPage',
    'navigateToUrl',
    'completedHtml',
    'navigateToUrlOnCondition',
    'completedHtmlOnCondition',
    'loadingHtml',
    'completedBeforeHtml',
    'rowTitleWidth',
    'focusFirstQuestionAutomatic',
  ];

  creator.onShowingProperty.add((sender, options) => {
    options.canShow = !propertyStopList.includes(options.property.name);
  });

  return (
    <>
      {hasResponses && (
        <Alert severity="warning" className="my-4 py-3">
          <h6 className="mb-0">
            <strong>Caution:</strong> This survey already has responses; editing questions or options could compromise the integrity of existing data
            and reports, except for minor text adjustments like fixing typos.
          </h6>
        </Alert>
      )}
      <div className="survey survey--designer" style={{ height: '100vh' }}>
        <SurveyCreatorComponent creator={creator} />
      </div>
    </>
  );
};

SurveyDesigner.propTypes = {
  survey: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  hasResponses: PropTypes.bool,
};

export default SurveyDesigner;
