import React, { useState } from 'react';
import Layout from '../layouts/application';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../constants/theme';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { createApi } from '../../apis/surveys/api';
import SurveysTable from '../../components/surveys/table';
import { ConfirmDialogProvider, useConfirmDialog } from '../../components/modals/confirm_dialog';
import { closeButtonSx } from '../../constants/dialog';

const Container = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [surveys, setSurveys] = useState(props.surveys);
  const [showOtherOrgSurveys, setShowOtherOrgSurveys] = useState(false);
  const [showOtherOrgSurveysDisabled, setShowOtherOrgSurveysDisabled] = useState(false);
  const [otherOrgSurveys, setOtherOrgSurveys] = useState([]);

  const api = createApi(props.base_url);
  const { showConfirmDialog, hideConfirmDialog } = useConfirmDialog();

  const handleClickOpen = (e) => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setName(null);
    setDescription(null);
  };

  const handleSave = async () => {
    const response = await api.create({ name, description });
    setSurveys([...surveys, response]);
    handleClose();
  };

  const handleDelete = async (id) => {
    await api.delete(id);
    setSurveys(surveys.filter((survey) => survey.id !== id));
    hideConfirmDialog();
  };

  const confirmDelete = (id) => {
    showConfirmDialog({
      title: 'Delete Confirmation',
      description: 'Are you sure you want to delete this Survey?',
      actions: [
        {
          label: 'Cancel',
          onClick: () => hideConfirmDialog(),
          variant: 'text',
          color: 'primary',
        },
        {
          label: 'Delete',
          onClick: () => handleDelete(id),
          variant: 'contained',
          color: 'primary',
          autoFocus: false,
        },
      ],
      maxWidth: 'xs',
    });
  };

  const handleEdit = (id) => {
    window.location.href = `${props.base_url}/${id}/edit`;
  };

  const handleClone = async (id) => {
    try {
      const response = await api.clone(id, props.org_id);
      setSurveys([...surveys, response]);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleShowAllOrgSurveys = async () => {
    if (!showOtherOrgSurveys) {
      setShowOtherOrgSurveysDisabled(true);
      const response = await api.getOtherOrgSurveys(props.org_id);
      setOtherOrgSurveys(response);
      setShowOtherOrgSurveys(true);
      setShowOtherOrgSurveysDisabled(false);
    } else {
      setShowOtherOrgSurveys(false);
    }
  };

  return (
    <>
      <div className="w-100 d-flex flex-wrap justify-content-between mt-5">
        <Button variant="contained" onClick={handleClickOpen} data-cy="add-survey">
          New Survey
        </Button>
        {props.allow_cross_org_cloning && (
          <FormControlLabel
            required
            label="Display Surveys from All Organizations"
            disabled={showOtherOrgSurveysDisabled}
            control={<Checkbox checked={showOtherOrgSurveys} onChange={toggleShowAllOrgSurveys} name="showOtherOrgSurveys" />}
          />
        )}
      </div>
      <Dialog fullWidth open={isModalOpen} onClose={handleClose}>
        <DialogTitle>New Survey</DialogTitle>
        <IconButton aria-label="close" onClick={handleClose} sx={closeButtonSx}>
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Title"
            type="text"
            fullWidth
            variant="standard"
            inputProps={{ 'data-cy': 'add-survey-title' }}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            margin="dense"
            id="name"
            label="Description"
            type="text"
            fullWidth
            multiline
            variant="standard"
            inputProps={{ 'data-cy': 'add-survey-description' }}
            onChange={(e) => setDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSave} data-cy="button-save-new-survey">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Paper sx={{ p: 3, mt: 3 }}>
        <SurveysTable surveys={surveys} onDelete={confirmDelete} onClone={handleClone} onEdit={handleEdit} emptyMessage="No surveys to display" />
      </Paper>

      {showOtherOrgSurveys && (
        <Paper sx={{ p: 3, mt: 3 }}>
          <SurveysTable showOrgColumn={true} surveys={otherOrgSurveys} onClone={handleClone} emptyMessage="No surveys to display" />
        </Paper>
      )}
    </>
  );
};
export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <ConfirmDialogProvider>
          <Container {...props} />
        </ConfirmDialogProvider>
      </ThemeProvider>
    </Layout>
  );
};
